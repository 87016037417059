import React from 'react'
import ProductHeader from '../templates/ProductPage/ProductHeader'
import SEO from '../components/seo'

const ConditionUtilisation = () => {
  return (
    <>
      <SEO title="Condition d'utilisation" description="Page Condition d'utilisation" />
      <ProductHeader title={'Condition d\'utilisation'} />

      <div className='page-legale'>

        <p><i>applicables à compter du <span
          className='mention'
          data-mention='{{runtime:currentDate}}'
                                       >22/09/2021
        </span>
        </i>
        </p>
        <h3><strong>ARTICLE 1. PARTIES</strong></h3>
        <p>Les présentes conditions générales sont applicables entre <span
          className='mention'
          data-mention='{{company:companyName}}'
                                                                     >JH &amp; Co
        </span>, <span
                                                                       className='mention' data-mention='{{company:companyType}}'
                                                                              >SARL
                                                                              </span>, capital social : <span
                   className='mention' data-mention='{{company:capital}}'
                                           >1000
                                           </span> €, enregistrée au&nbsp;RCS de <span
                                                                                                          className='mention' data-mention='{{company:registrationState}}'
                                                                                                                                              >Creteil
                                                                                                                                              </span> en <span
                                                                                   className='mention'
                                                                                   data-mention='{{company:registrationCountry}}'
                                                                                            >France
                                                                                            </span> le <span
                                                                                                                                                           className='mention' data-mention='{{company:registrationDate}}'
                                                                                                                                                                    >28/11/2016
                                                                                                                                                                    </span>, sous le numéro <span
                                                                                                         className='mention' data-mention='{{company:companyRegistrationID}}'
                                                                                                                               >82391901400014
                                                                                                                               </span>, siège social
          : <span
              className='mention'
              data-mention='{{company:fullAddress}}'
            >1 bis rue blanqui, 94200 Ivry sur seine, FR
          </span>, téléphone
          : <span className='mention' data-mention='{{company:phone}}'>+33620142775</span>, email : <span
              className='mention' data-mention='{{company:email}}'
                                                                                                    >jhandco.dev@gmail.com
          </span>, TVA non applicable,
          article 293B du CGI, ci-après «&nbsp;l’Editeur&nbsp;» et toute personne, physique ou morale, de droit privé ou
          de droit public, inscrite sur le Site pour acheter un Produit, ci-après «&nbsp;le Client&nbsp;».
        </p>
        <p>&nbsp;</p>
        <h3><strong>ARTICLE 2. DEFINITIONS</strong></h3>
        <p>« <strong>Client&nbsp;</strong>» : toute personne, physique ou morale, de droit privé ou de droit public,
          inscrite sur le Site.
        </p>
        <p>«&nbsp;<strong>Contenus du Site&nbsp;»</strong> : éléments de toute nature publiés sur le Site, protégés ou
          non par un droit de propriété intellectuelle, tel que textes, images, designs, présentations, vidéos, schémas,
          structures, bases de données ou logiciels.&nbsp;
        </p>
        <p>«&nbsp;<strong>L’Editeur</strong>&nbsp;» : <span
          className='mention'
          data-mention='{{company:companyName}}'
                                                      >JH &amp; Co
        </span>, <span
                                                        className='mention' data-mention='{{company:companyType}}'
                                                               >SARL
                                                               </span> pris en sa qualité d’éditeur du Site.
        </p>
        <p>«&nbsp;<strong>Internaute</strong>&nbsp;» : toute personne, physique ou morale, de droit privé ou de droit
          public, se connectant au Site.
        </p>
        <p>«&nbsp;<strong>Produit</strong>&nbsp;»&nbsp;: bien de toute nature vendu sur le Site par l’Editeur aux
          Clients.
        </p>
        <p>«&nbsp;<strong>Site&nbsp;</strong>» : site internet accessible à l’URL <span
          className='mention'
          data-mention='{{company:websiteName}}'
                                                                                  >https://jhnco.fr
        </span>,
          ainsi que les sous-sites, sites miroirs, portails et variations d’URL y afférents.
        </p>
        <h3><strong>ARTICLE 3. CHAMP D’APPLICATION</strong></h3>
        <p>Le Site est d'accès libre et gratuit à tout Internaute. La navigation sur le Site suppose l'acceptation par
          tout Internaute des présentes conditions générales. La simple connexion au Site, par quelque moyen que ce
          soit, notamment par l'intermédiaire d'un robot ou d'un navigateur, emportera acceptation pleine et entière des
          présentes conditions générales. Lors de l’inscription sur le Site, cette acceptation sera confirmée par le
          fait de cocher la case correspondante.
        </p>
        <p>L'Internaute reconnaît du même fait en avoir pris pleinement connaissance et les accepter sans
          restriction.&nbsp;
        </p>
        <p>Le fait de cocher la case susvisée sera réputé avoir la même valeur qu'une signature manuscrite de la part de
          l’Internaute. L'Internaute reconnaît la valeur de preuve des systèmes d'enregistrement automatique de
          L’Editeur et, sauf pour lui d'apporter preuve contraire, il renonce à les contester en cas de litige.
        </p>
        <p>Les présentes conditions générales sont applicables aux relations entre les parties à l’exclusion de toutes
          autres conditions, et notamment celles de l’Internaute.&nbsp;
        </p>
        <p>L'acceptation des présentes conditions générales suppose de la part des Internautes qu'ils jouissent de la
          capacité juridique nécessaire pour cela, ou à défaut qu'ils en aient l'autorisation d'un tuteur ou d'un
          curateur s'ils sont incapables, de leur représentant légal s'ils sont mineurs, ou encore qu'ils soient
          titulaires d'un mandat s'ils agissent pour le compte d'une personne morale.
        </p>
        <h3><strong>ARTICLE 4. OBJET DU SITE</strong></h3>
        <p>Le Site a pour objet la vente de Produits aux Clients.</p>
        <h3><strong>ARTICLE 5. SERVICE CLIENTELE</strong></h3>
        <p>&nbsp;</p>
        <p>Le service clientèle du Site est accessible depuis la page contact du site: <span
          className='mention'
          data-mention='{{company:afterSaleWebPage}}'
                                                                                       >https://jhnco.fr/contact
        </span> ou
          par email à <span
            className='mention'
            data-mention='{{company:afterSaleEmail}}'
                      >jhandcoparis@gmail.com
          </span> ou par courrier
          postal à l’adresse indiquée à l’article 1 des présentes conditions générales.
        </p>
        <h3><strong>ARTICLE 6. ESPACE PERSONNEL</strong></h3>
        <h4><strong>6.1. Création de l’espace personnel</strong></h4>
        <p>La création d'un espace personnel est un préalable indispensable à toute commande d'un Internaute sur le
          Site. A cette fin, l’Internaute sera invité à fournir un certain nombre d'informations personnelles. Certaines
          de ces informations sont réputées indispensables à la création de l'espace personnel. Le refus par un
          Internaute de fournir lesdites informations aura pour effet d'empêcher la création de l'espace personnel ainsi
          que, incidemment, la validation de la commande.&nbsp;
        </p>
        <p>Lors de la création de l'espace personnel, l'Internaute est invité à choisir un mot de passe. Ce mot de passe
          constitue la garantie de la confidentialité des informations contenues dans l’espace personnel. L'Internaute
          s'interdit donc de le transmettre ou de le communiquer à un tiers. A défaut, L’Editeur ne pourra être tenu
          pour responsable des accès non autorisés à l’espace personnel d'un Internaute.
        </p>
        <p>Le Client s’engage à procéder à une vérification régulière des données qui le concernent et à procéder en
          ligne, depuis son espace personnel, aux actualisations et modifications nécessaires.
        </p>
        <h4><strong>6.2. Contenu de l’espace personnel</strong></h4>
        <p>L’espace personnel permet au Client de consulter et de suivre toutes ses commandes effectuées sur le
          Site.
        </p>
        <p>Les pages relatives aux espaces personnels sont librement imprimables par le titulaire du compte en question,
          mais ne constituent nullement une preuve admissible par un tribunal. Elles n'ont qu'un caractère informatif
          destiné à assurer une gestion efficace de ses commandes par le Client.
        </p>
        <p>L’Editeur s'engage à conserver de façon sécurisée tous les éléments contractuels dont la conservation est
          requise par la loi ou la réglementation en vigueur.
        </p>
        <h4><strong>6.3. Suppression de l’espace personnel</strong></h4>
        <p>L’Editeur se réserve le droit de supprimer le compte de tout Client qui contrevient aux présentes conditions
          générales, notamment lorsque le Client fournit des informations inexactes, incomplètes, mensongères ou
          frauduleuses, ainsi que lorsque l’espace personnel d’un Client sera resté inactif depuis au moins une année.
          Ladite suppression ne sera pas susceptible de constituer une faute de L’Editeur ou un dommage pour le Client
          exclu, qui ne pourra prétendre à aucune indemnité de ce fait.
        </p>
        <p>Cette exclusion est sans préjudice de la possibilité, pour L’Editeur, d'entreprendre des poursuites d'ordre
          judiciaire à l'encontre du Client, lorsque les faits l'auront justifié.
        </p>
        <h3><strong>ARTICLE 7. DONNEES PERSONNELLES</strong></h3>
        <p>Dans le cadre de sa prestation, l’Editeur va être amené à traiter des données à caractère personnel de ses
          Clients.
        </p>
        <h4><strong>7.1. Identité du responsable du traitement</strong></h4>
        <p>Le responsable de la collecte et des données traitées sur le Site est l’Editeur.</p>
        <h4><strong>7.2. Identité du Délégué à la protection des données</strong></h4>
        <p>Le délégué à la protection des données est : Etienne Deshoulières, 121 boulevard de Sébastopol 75002
          Paris, <a href='mailto:contact@deshoulieres-avocats.com'>contact@deshoulieres-avocats.com</a>, 01 77 62 82
          03, <a href='http://www.deshoulieres-avocats.com' target='_blank'>www.deshoulieres-avocats.com</a>" en prenant
          toujours soin de créer un lien hypertexte sur l'URL de notre site
        </p>
        <h4><strong>7.3. Données collectées</strong></h4>
        <h5><strong>7.3.1. Données collectées auprès des clients</strong></h5>
        <p>Dans le cadre de ses relations contractuelles, l’Editeur peut être amené à collecter et traiter des
          informations de ses Clients, à savoir&nbsp;: <span className='mention' data-mention='{{privacy:collectInfo}}'>Email, Nom et prénom, Téléphone</span>.
        </p>
        <h5><strong>7.3.2. Finalités de la collecte de données personnelles</strong></h5>
        <p>Les données collectées lors de la relation contractuelle font l'objet d'un traitement automatisé ayant pour
          finalité de :
        </p>
        <ul>
          <li>exécuter les engagements contractuels ;</li>
          <li>contacter les Clients ;</li>
          <li>éviter toute activité illicite ou illégale ;</li>
          <li>faire respecter les conditions générales ;</li>
          <li>engager des procédures judiciaires ;</li>
          <li>vérifier l'identité des Clients ;</li>
        </ul>
        <h5><strong>7.3.3. Bases juridiques du traitement</strong></h5>
        <p>Les données collectées ont pour base juridique une relation contractuelle.</p>
        <h5><strong>7.3.4. Destinataires des données</strong></h5>
        <p>Les données collectées sont consultables uniquement par&nbsp;l’Editeur dans les limites&nbsp;strictement
          nécessaires à l’exécution des engagements contractuels.
        </p>
        <p>Ces données, que ce soit sous forme individuelle ou agrégée, ne sont jamais rendues
          librement&nbsp;visualisables par une personne physique tierce.
        </p>
        <h5><strong>7.3.5. Durée de conservation des données personnelles</strong></h5>
        <p>Les données personnelles collectées sont conservées pendant le temps de la relation contractuelle, et pendant
          le temps durant lequel la responsabilité de l’Editeur peut être engagée.<strong>&nbsp;</strong>
        </p>
        <p>Passé le délai de conservation, l’Editeur s’engage à supprimer définitivement les données des personnes
          concernées sans en conserver une copie.
        </p>
        <h5><strong>7.3.6. Sécurité et confidentialité des données personnelles</strong></h5>
        <p>Les données personnelles sont conservées dans des conditions sécurisées, selon les moyens actuels de la
          technique, dans le respect des dispositions&nbsp;du Règlement général sur la&nbsp;protection des données et de
          la législation nationale en vigueur.
        </p>
        <p>L’accès aux locaux de&nbsp;l’Editeur est également sécurisé.</p>
        <h5><strong>7.3.7. Minimisation des données</strong></h5>
        <p>L’Editeur peut également collecter et traiter toute donnée transmise volontairement par ses Clients.</p>
        <p>L’Editeur oriente ses Clients afin qu’il fournisse des données à caractère personnel strictement nécessaires
          à l’exécution des engagements contractuels.&nbsp;
        </p>
        <p>L’Editeur s’engage à ne conserver et traiter que les données strictement nécessaires à ses activités
          professionnelles, et supprimera toute donnée reçue non utile à ses activités dans les plus brefs délais.
        </p>
        <h4><strong>7.4. Respect des droits</strong></h4>
        <p>Les Clients de&nbsp;l’Editeur&nbsp;disposent des droits suivants concernant leurs données personnelles,
          qu’ils peuvent exercer en écrivant à l’adresse postale de&nbsp;l’Editeur&nbsp;&nbsp;ou en remplissant le
          formulaire contact en ligne.
        </p>
        <h5><strong>7.4.1. Droit d’information, d’accès et de communication des données</strong></h5>
        <p>Les Clients de l’Editeur ont la possibilité&nbsp;d’accéder aux données personnelles qui&nbsp;les
          concernent.
        </p>
        <p>En raison de l’obligation de sécurité et de confidentialité dans le traitement des données à&nbsp;caractère
          personnel qui incombe à&nbsp;l’Editeur,&nbsp;les demandes seront uniquement traitées&nbsp;si&nbsp;les Clients
          rapportent la preuve de&nbsp;leur identité, notamment par la production d’un scan de&nbsp;leur titre
          d’identité valide (en cas de demande par&nbsp;le formulaire électronique dédié) ou d’une photocopie signée
          de&nbsp;leur titre d’identité valide (en cas de demande adressée par écrit), tous deux accompagnés de la
          mention «&nbsp;j’atteste sur l’honneur que la copie de cette pièce d’identité est conforme à l’original. Fait
          à … le …&nbsp;», suivie de&nbsp;leur signature.
        </p>
        <p>Pour les aider dans&nbsp;leur&nbsp;démarche,&nbsp;les Clients trouveront&nbsp;<a
          href='https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces' target='_blank'
                                                                                         >ici
        </a>&nbsp;un modèle
          de courrier élaboré par la Cnil.
        </p>
        <h5><strong>7.4.2. Droit de rectification, de suppression et droit à l’oubli des données</strong></h5>
        <p>Les Clients de l’Editeur ont la&nbsp;possibilité de demander la rectification, la mise à jour, le
          verrouillage ou encore l’effacement de leurs données personnelles qui peuvent s’avérer le cas échéant
          inexactes, erronées, incomplètes ou obsolètes.
        </p>
        <p>Les Clients de l’Editeur peuvent également définir des directives générales et particulières relatives au
          sort des données à&nbsp;caractère personnel après&nbsp;leur décès. Le cas échéant, les héritiers d’une
          personne décédée peuvent exiger de prendre en considération le décès de leur proche et/ou de procéder aux
          mises à jour nécessaires.
        </p>
        <p>Pour les aider dans&nbsp;leur&nbsp;démarche,&nbsp;les Clients trouveront&nbsp; <a
          href='https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees'
          target='_blank'
                                                                                          >ici
        </a>&nbsp;un modèle de courrier élaboré par la Cnil.
        </p>
        <h5><strong>7.4.3. Droit d’opposition au traitement de données</strong></h5>
        <p>Les Clients de l’Editeur ont la possibilité de&nbsp;s’opposer à un traitement de&nbsp;leurs données
          personnelles.&nbsp;
        </p>
        <p>Pour les aider dans&nbsp;leur&nbsp;démarche,&nbsp;les Clients trouveront&nbsp; <a
          href='https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees'
          target='_blank'
                                                                                          >ici
        </a>&nbsp;un modèle de courrier élaboré par la Cnil.
        </p>
        <h5><strong>7.4.4. Droit à la portabilité des données</strong></h5>
        <p>Les Clients de l’Editeur ont le droit de recevoir les données personnelles qu’ils ont fournies
          à&nbsp;l’Editeur dans un format transférable, ouvert et lisible.&nbsp;
        </p>
        <h5><strong>7.4.5. Droit à la limitation du traitement</strong></h5>
        <p>Les Clients de l’Editeur ont le droit de demander que le traitement de leurs données personnelles
          par&nbsp;l’Editeur soit limité. Ainsi, leurs données ne pourront qu’être conservées et non plus utilisées
          par&nbsp;l’Editeur.
        </p>
        <h5><strong>7.4.6. Délais de réponse</strong></h5>
        <p>L’Editeur s’engage à&nbsp;répondre à&nbsp;toute demande d’accès, de rectification ou d’opposition ou toute
          autre demande complémentaire d’informations dans un délai raisonnable qui ne saurait dépasser 1 mois à compter
          de la réception de&nbsp;la demande.
        </p>
        <h5><strong>7.4.7. Plainte auprès de l’autorité compétente</strong></h5>
        <p>Si&nbsp;les Clients de l’Editeur considèrent que&nbsp;l’Editeur ne respecte pas ses obligations au regard de
          leurs données à caractère personnel,&nbsp;ils peuvent adresser une plainte ou une demande auprès de
          l’autorité&nbsp;compétente. En France, l’autorité&nbsp;compétente est la Cnil à laquelle&nbsp;ils
          peuvent&nbsp;adresser une demande&nbsp;<a
            href='https://www.cnil.fr/fr/plaintes/internet'
            target='_blank'
                                                 >ici
          </a>.
        </p>
        <h4><strong>7.5. Transfert des données collectées&nbsp;</strong></h4>
        <h5><strong>7.5.1. Transfert à des partenaires</strong></h5>
        <p>L’Editeur a recours&nbsp;à des prestataires habilités pour faciliter la collecte et le traitement des données
          de ses Clients. Ces prestataires peuvent être situés en dehors de l’Union Européenne.
        </p>
        <p>L’Editeur&nbsp;s’est préalablement assuré de la mise en œuvre par ses prestataires de garanties adéquates et
          du respect de conditions strictes en matière de confidentialité, d’usage et de protection des données, par
          exemple via le Privacy Shield états-unien.
        </p>

        <p>&nbsp;</p>
        <h5><strong>7.5.2. Transfert sur réquisition ou décision judiciaire</strong></h5>
        <p>Les Clients consentent également à ce que l’Editeur&nbsp;communique les données collectées à toute personne,
          sur réquisition d’une autorité étatique ou sur décision judiciaire.&nbsp;
        </p>
        <h5><strong>7.5.3. Transfert dans le cadre d'une fusion ou d'une acquisition</strong></h5>
        <p>Si l’Editeur est impliqué dans une fusion, une vente d'actifs, une opération de financement, une liquidation
          ou banqueroute ou dans une acquisition de tout ou partie de son activité par une autre société, les Clients
          consentent à ce que les données collectées soient transmises par l’Editeur à cette société et que cette
          société opère les traitements de données personnelles visés dans les présentes Conditions générales de service
          au lieu et place de l’Editeur.
        </p>
        <h3><strong>ARTICLE 8. PROPRIETE INTELLECTUELLE&nbsp;</strong></h3>
        <h4><strong>8.1. Protection légale des Contenus du Site</strong></h4>
        <p>Les Contenus du Site sont susceptibles d'être protégés par le droit d'auteur et le droit des bases de
          données. Toute représentation, reproduction, traduction, adaptation ou transformation, intégrale ou partielle,
          réalisée illégalement et sans le consentement de l’Editeur ou de ses ayants droit ou ayants cause constitue
          une violation des Livres I et III du Code de la propriété intellectuelle et sera susceptible de donner lieu à
          des poursuites judiciaires pour contrefaçon.
        </p>
        <h4><strong>8.2. Protection contractuelle des Contenus du Site</strong></h4>
        <p>L'Internaute s'engage contractuellement à l'égard de l'Editeur à ne pas utiliser, reproduire ou représenter,
          de quelque manière que ce soit, les Contenus du Site, qu'ils soient ou non protégés par un droit de propriété
          intellectuelle, à une autre fin que celle de leur lecture par un robot ou un navigateur. Cette interdiction
          n'est pas applicable aux robots d'indexation ayant pour seul objet de scanner le contenu du Site aux fins
          d'indexation.
        </p>
        <h4><strong>8.3. Protection des conditions générales</strong></h4>
        <p>Les conditions générales du Site, rédigées par le cabinet Deshoulières Avocats Associés (<a
          href='http://www.deshoulieres-avocats.com' target='_blank'
                                                                                                    >www.deshoulieres-avocats.com
        </a>), sont protégées
          par le droit commercial. Toute reproduction, intégrale ou partielle, réalisée sans le consentement de
          Deshoulières Avocats Associés sera susceptible de donner lieu à des poursuites judiciaires pour parasitisme.
        </p>
        <h3><strong>ARTICLE 9. STIPULATIONS FINALES</strong></h3>
        <h4><strong>9.1. Droit applicable</strong></h4>
        <p>Les présentes conditions générales sont soumises à l'application du droit français.&nbsp;</p>
        <h4><strong>9.2. Modifications des présentes conditions générales</strong></h4>
        <p>Les présentes conditions générales peuvent être modifiées à tout moment par L’Editeur. Les conditions
          générales applicables au Client sont celles en vigueur au jour de sa commande ou de sa connexion sur le
          présent Site, toute nouvelle connexion à l'espace personnel emportant acceptation le cas échéant des nouvelles
          conditions générales.&nbsp;
        </p>
        <h4><strong>9.3. Litiges</strong></h4>
        <p>En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous litiges qui pourraient survenir dans le cadre de
          l'exécution des présentes conditions générales et dont la solution n’aura pu être trouvée préalablement à
          l’amiable entre les parties devra être soumis.
        </p>
        <p>En outre, le Client est informé de l’existence de la plateforme de règlement en ligne des litiges,
          accessibles à l’adresse URL suivante&nbsp;: <a
            href='https://ec.europa.eu/consumers/odr/main/?event=main.home2.show'
            target='_blank'
                                                      >https://ec.europa.eu/consumers/odr/main/?event=main.home2.show
          </a>
        </p>
        <p>Depuis le 1er janvier 2016, la médiation est obligatoire pour tous. Ainsi, tout professionnel vendant à des
          particuliers, est tenu de communiquer les coordonnées d'un Médiateur compétent en cas de litige, et ce peu
          importe qu'il vende à distance ou dans un magasin physique (Source: FEVAD).
        </p>
        <p><span className='mention' data-mention='{{company:mediationServiceName}}'>Contact</span> &nbsp;/&nbsp; <span
          className='mention' data-mention='{{company:mediationUrl}}'
                                                                                                                  >https://jhnco.fr/contact
        </span>
        </p>
        <h4><strong>9.4. Entièreté</strong></h4>
        <p>La nullité d'une des clauses du présent contrat n'entraînera pas la nullité des autres clauses du contrat ou
          du contrat dans sa globalité, qui garderont leur plein effet et portée. Dans une telle hypothèse, les parties
          devront dans la mesure du possible remplacer la stipulation annulée par une stipulation valable correspondant
          à l'esprit et à l'objet des présentes.
        </p>
        <h4><strong>9.5. Non-renonciation</strong></h4>
        <p>L'absence d'exercice par L’Editeur des droits qui lui sont reconnus par les présentes ne pourra en aucun cas
          être interprétée comme une renonciation à faire valoir lesdits droits.
        </p>
        <h4><strong>9.6. Démarchage téléphonique</strong></h4>
        <p>Le Client est informé qu’il a la possibilité de s’inscrire sur la liste d'opposition au démarchage
          téléphonique à l’adresse <a
            href='http://www.bloctel.gouv.fr/'
            target='_blank'
                                   >http://www.bloctel.gouv.fr/
          </a>.&nbsp;
        </p>
        <h4><strong>9.7. Langues des présentes conditions générales</strong></h4>
        <p>Les présentes conditions générales sont proposées en français.</p>
        <h4><strong>9.8. Clauses abusives</strong></h4>
        <p>Les stipulations des présentes conditions générales s'appliquent sous réserve du respect des dispositions
          impératives du Code de la consommation concernant les clauses abusives dans les contrats conclus entre un
          professionnel et un consommateur.
        </p>
      </div>
    </>
  )
}

export default ConditionUtilisation
